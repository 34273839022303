<template>
  <div class="page-content">
    <div class="content-box">
      <div class="form-box" v-loading="loading">
        <SectionTitle title="月度之星" />
        <el-form ref="form" :model="form" label-width="120px" :rules="rules" :disabled="isView">
          <el-form-item label="项目班：" prop="ProjectClassId">
            <el-select class="mr-10" v-model="form.ProjectClassId" style="width:100%" filterable>
              <el-option v-for="item in projectClassList" :key="item.Id" :label="item.Name" :value="item.Id" @click.native="getStudentList(item.Id)"></el-option>
            </el-select>
            <!-- <el-select class="mr-10" v-model="form.JobId" style="width:100%" filterable>
              <el-option v-for="item in jobList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="学生：" prop="StudentId">
            <el-select class="mr-10" v-model="form.StudentId" style="width:100%" filterable>
              <el-option v-for="item in studentList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
            </el-select>
            <!-- <el-select class="mr-10" v-model="form.JobId" style="width:100%" filterable>
              <el-option v-for="item in jobList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
            </el-select> -->
          </el-form-item>
          <!-- <el-form-item label="学生名称：" prop="Name">
            <el-input
              v-model="form.Name"
              placeholder="请输入工作项目名称"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="学生照片：" prop="Avatar">
            <el-image v-if="isView" style="width: 100px; height: 100px" :src="form.Avatar" fit="scale-down"></el-image>
            <picture-selector v-else v-model="form.Avatar" uploadPath="/File/UploadSingleFile" name="CoverImage" ref="CoverImage" remarks="150*150" @input="updateForm">
            </picture-selector>
          </el-form-item>
          <el-form-item label="选择月份：" prop="Date">
            <el-date-picker v-model="form.Date" type="month" value-format="yyyy-MM" placeholder="选择月">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="评价内容：">
            <el-input type="textarea" v-model="form.Description" placeholder="请输入工作内容" resize="none" rows="5"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="footer">
        <el-button v-if="!isView && !isEdit" type="primary" plain @click="handleSave" :loading="btnLoading">保存</el-button>
        <el-button v-if="!isView && isEdit" type="primary" @click="handleEdit" :loading="btnLoading">发布</el-button>
        <el-button type="default" @click="$router.go(-1)">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import PictureSelector from "@/components/PictureSelector.vue";
import contentTools from "@/mixins/content-tools";
import { StaffTypeEnum } from "@/utils/constant";
import { mapGetters } from "vuex";
import { getAllList as getProjectClassList } from "@/api/internshipLayout";
import {
  getProjectClassStudentList,
  createEntity,
  getInfo,
  updateEntity,
} from "@/api/monthStar";
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    isView: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    SectionTitle,
    PictureSelector,
  },
  mixins: [contentTools],
  computed: {
    ...mapGetters(["employee"]),
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    },
  },
  data() {
    return {
      btnLoading: false,
      loading: false,

      form: {
        ProjectClassId: "",
        Date: "",
        StudentId: "",
        Avatar: "",
        Description: "",
      },
      jobList: [],
      studentList: [],
      rules: {
        StudentId: [
          { required: true, message: "请选择学生", trigger: "change" },
        ],
        ProjectClassId: [
          { required: true, message: "请选择项目班", trigger: "change" },
        ],
        Avatar: [
          { required: true, message: "请选择学生照片", trigger: "change" },
        ],
        // Description: [
        //   { required: true, message: "请输入内容", trigger: "blur" },
        // ],
        Date: [{ required: true, message: "请选择月份", trigger: "change" }],
      },
      EnterpriseId: null,
      projectClassList: [],
    };
  },
  created() {
    this.initialize();
    this.getProjectClassList();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        if (this.isEdit || this.isView) {
          await this.getInfo();
          await this.getStudentList(this.form.ProjectClassId);
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
    async getStudentList(id) {
      this.loading = true;
      try {
        const { Result } = await getProjectClassStudentList(id);
        debugger;
        // this. = Result;
        this.studentList = Result;
        console.log("数据--:", Result);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    updateForm(name, Path, path) {
      this.form.Avatar = path;
    },
    async handleSave() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          try {
            const Result = await createEntity(this.form);
            // this. = Result;
            if (Result.IsSuccess) {
              this.$message.success("添加成功");
              this.$router.go(-1);
            }
            this.loading = false;
          } catch (error) {
            console.log(error);
            this.loading = false;
          }
        } else {
          this.$message.error("您没有输入必填项");
          return false;
        }
      });
    },
    async handleEdit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          try {
            const Result = await updateEntity({
              ...this.form,
              Id: this.$route.query.id,
            });
            // this. = Result;
            if (Result.IsSuccess) {
              this.$message.success("修改成功");
              this.$router.go(-1);
            }
            this.loading = false;
          } catch (error) {
            console.log(error);
            this.loading = false;
          }
        } else {
          this.$message.error("您没有输入必填项");
          return false;
        }
      });
    },
    async getInfo() {
      console.log(this.$route.query.id);
      const res = await getInfo({ id: this.$route.query.id });
      if (res.IsSuccess) {
        this.form.ProjectClassId = res.Result.ProjectClassId;
        this.form.Date = res.Result.Date;
        this.form.StudentId = res.Result.StudentId;
        this.form.Avatar = res.Result.Avatar;
        this.form.Description = res.Result.Description;
      }
    },
    async getProjectClassList() {
      const { Result = [] } = await getProjectClassList();
      this.projectClassList = Result;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  height: calc(100% - 80px);
  overflow: auto;
}
.form-box {
  width: 850px;
  margin: 0 auto;
}
.title {
  font-weight: bold;
}
.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  height: 80px;
  box-shadow: 0px -2px 8px 0px rgba(51, 51, 51, 0.08);
  border-radius: 0px 0px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-add {
  color: #409eff;
  font-size: 22px;
  margin-right: 8px;
}
.hover-danger {
  font-size: 22px;
}
.form-add:hover {
  opacity: 0.8;
}
</style>
